<template>
  <van-popup v-model="visible" round closeable position="bottom" get-container="body" :style="{ height: '80%' }">
    <div class="main-wrap-popup">
      <div class="main-header">
        <div class="title">报障详情</div>
      </div>
      <div class="main-container layout-1">
        <div class="row-1">
          <div class="text-1-wrap">
            <div class="icon-wrap">
              <van-icon name="clock-o"/>
            </div>
            <div class="text-1">资产{{ title }}</div>
          </div>
          <div class="text-2">资产{{ title }}</div>
        </div>

        <div class="row-3">
          <div class="title">{{ name }}</div>
          <div class="row-wrap">
            <div class="left-img"><img class="img"
                                       :src="image"
                                       alt=""/></div>
            <div class="right-list">
              <div class="list-item">
                <span class="label">{{$VUEAPPDEVICECODE()}}</span>
                <span class="value">{{ deviceCode }}</span>
              </div>
              <div class="list-item">
                <span class="label">卡片号</span>
                <span class="value">{{ label }}</span>
              </div>
              <div class="list-item">
                <span class="label">设备分类</span>
                <span class="value">{{ categoryName }}</span>
              </div>
              <div class="list-item">
                <span class="label">品牌</span>
                <span class="value">{{ brandName }}</span>
              </div>
              <div class="list-item">
                <span class="label">型号</span>
                <span class="value">{{ modelName }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row-4">
          <div class="list-item">
            <span class="label">报障人</span>
            <span class="value">{{ reportUser }}</span>
          </div>
          <div class="list-item">
            <span class="label">报障原因</span>
            <span class="value">{{ detail }}</span>
          </div>
          <div class="list-item">
            <span class="label">报障时间</span>
            <span class="value">{{ reportTime }}</span>
          </div>
        </div>

        <div class="row-4" v-if="repairStatus>=5">
          <div class="list-item">
            <span class="label">维修员</span>
            <span class="value">{{ repairUser }}</span>
          </div>
          <div class="list-item">
            <span class="label">故障原因</span>
            <span class="value">{{ repairDetail }}</span>
          </div>
          <div class="list-item">
            <span class="label">维修时间</span>
            <span class="value">{{ repairTime }}</span>
          </div>
        </div>

      </div>
    </div>
  </van-popup>
</template>

<script>
import { formatStatusData } from '@/baseConfig/orderStatusOptions'

export default {
  name: 'detailPopup',
  components: {},
  computed: {
    image() {
      if (this.$valueIsExist(this.dataInfo, 'photo') && this.dataInfo['photo'] != '') {
        let photo = this.dataInfo['photo']
        return `${window.ipConfig.VUE_APP_BASEURL}/api/image/get?id=${this.$getPhotosId(photo)}`
      }
      return this.$replacePhoto('')
    },
    repairStatus() {
      let status = 0
      if (this.$valueIsExist(this.reportInfo, 'status')) {
        status = this.reportInfo['status']
      }
      return status
    },
    repairTime() {
      if (this.$valueIsExist(this.reportInfo, 'repairTime') == false) {
        return ''
      }
      return this.formatDate(this.reportInfo['repairTime'])
    },
    repairDetail() {
      if (this.$valueIsExist(this.reportInfo, 'repairDetail') == false) {
        return ''
      }
      return this.reportInfo['repairDetail']
    },
    repairUser() {
      if (this.$valueIsExist(this.reportInfo, 'repairUser') == false) {
        return ''
      }
      return this.reportInfo['repairUser']
    },
    reportTime() {
      if (this.$valueIsExist(this.reportInfo, 'reportTime') == false) {
        return ''
      }
      return this.formatDate(this.reportInfo['reportTime'])
    },
    detail() {
      if (this.$valueIsExist(this.reportInfo, 'detail') == false) {
        return ''
      }
      return this.reportInfo['detail']
    },
    reportUser() {
      if (this.$valueIsExist(this.reportInfo, 'reportUser') == false) {
        return ''
      }
      return this.reportInfo['reportUser']
    },
    title() {
      let orderStatus = -1
      if (this.$valueIsExist(this.dataInfo, 'orderStatus')) {
        orderStatus = this.dataInfo['orderStatus']
      }
      return formatStatusData(orderStatus).label
    },
    estimatedAmount() {
      if (this.$valueIsExist(this.dataInfo, 'estimatedAmount') == false) {
        return '0'
      }
      return this.dataInfo['estimatedAmount']
    },
    billing() {
      if (this.$valueIsExist(this.dataInfo, 'billingType') == false) {
        return '次'
      }
      return this.formatBillingType(this.dataInfo['billingType'])
    },
    tenantName() {
      if (this.$valueIsExist(this.dataInfo, 'tenantName') == false) {
        return ''
      }
      return this.dataInfo['tenantName']
    },
    orderUser() {
      if (this.$valueIsExist(this.dataInfo, 'orderUser') == false) {
        return ''
      }
      return this.dataInfo['orderUser']
    },
    status() {
      let status = 0
      if (this.$valueIsExist(this.dataInfo, 'status')) {
        status = this.dataInfo['status']
      }
      return this.getStatus(status)
    },
    severity() {
      let severity = 0
      if (this.$valueIsExist(this.dataInfo, 'severity')) {
        severity = this.dataInfo['severity']
      }
      return this.getSeverityData(severity)
    },
    deviceCode() {
      if (this.$valueIsExist(this.dataInfo, 'code') == false) {
        return ''
      }
      return this.dataInfo['code']
    },
    modelName() {
      if (this.$valueIsExist(this.dataInfo, 'modelName') == false) {
        return ''
      }
      return this.dataInfo['modelName']
    },
    brandName() {
      if (this.$valueIsExist(this.dataInfo, 'brandName') == false) {
        return ''
      }
      return this.dataInfo['brandName']
    },
    categoryName() {
      if (this.$valueIsExist(this.dataInfo, 'categoryName') == false) {
        return ''
      }
      return this.dataInfo['categoryName']
    },
    name() {
      if (this.$valueIsExist(this.dataInfo, 'name') == false) {
        return ''
      }
      return this.dataInfo['name']
    },
    label() {
      if (this.$valueIsExist(this.dataInfo, 'label') == false) {
        return ''
      }
      return this.dataInfo['label']
    },
    price() {
      if (this.$valueIsExist(this.dataInfo, 'price') == false) {
        return '0'
      }
      return this.dataInfo['price']
    },
    startTime() {
      if (this.$valueIsExist(this.dataInfo, 'startTime') == false) {
        return ''
      }
      return this.formatDate(this.dataInfo['startTime'])
    },
    orderTime() {
      if (this.$valueIsExist(this.dataInfo, 'orderTime') == false) {
        return ''
      }
      return this.formatDate(this.dataInfo['orderTime'])
    },
    approvalTime() {
      if (this.$valueIsExist(this.dataInfo, 'approvalTime') == false) {
        return ''
      }
      return this.formatDate(this.dataInfo['approvalTime'])
    }
  },
  data() {
    return {
      visible: false,
      dataInfo: {},
      reportInfo: {},
      rate: 2
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    show(info) {
      this.visible = true
      this.getDetail(info)
      this.getReportDetail(info)
    },
    hide() {
      this.visible = false
    },
    getDetail(info) {
      this.$api.deviceWorkManager.orderDataInfo({
        id: info.id
      })
          .then(res => {
            this.dataInfo = res.data
          })
          .catch(() => {
          })
    },
    getReportDetail(info) {
      this.$api.deviceRepair
          .reportDataInfo({
            id: info.id
          })
          .then(res => {
            let report = {}
            if (typeof res.data['report'] == 'undefined' || res.data['report'] != null) {
              report = res.data['report']
            }
            this.reportInfo = report
          })
          .catch(() => {
          })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
@import '@styles/layout.scss';

.main-wrap-popup {
  background: linear-gradient(360deg, rgba(114, 171, 253, 0) 0%, #568efc 70%, #3e73fb 100%);
  background-size: 100% 195px;
  background-repeat: no-repeat;

  .main-header {
    .title {
      color: #fff;
    }
  }

  .main-container {
    padding-top: 20px;
  }
}
</style>
