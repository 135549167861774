const options = [{
  value: -2,
  label: '超时',
  subLabel: '超时。',
  color: '#e68b35'
},
  {
    value: -1,
    label: '已取消',
    subLabel: '您的预约已取消。',
    color: '#666666'
  },
  {
    value: 0,
    label: '已预约',
    subLabel: '您的预约正在等待审批,请耐心等待。',
    color: '#27aeb8'
  },
  {
    value: 1,
    label: '审批通过',
    subLabel: '您的预约已审批通过,可以扫码使用该设备。',
    color: '#00aa00'
  },
  {
    value: 2,
    label: '审批未通过',
    subLabel: '抱歉,您的预约已审批不通过。',
    color: '#aa00ff'
  },
  {
    value: 3,
    label: '借用中',
    subLabel: '设备正在借用中,请注意保养消毒。',
    color: '#e44545'
  },
  {
    value: 4,
    label: '报障中',
    subLabel: '设备报障中,正在等待工程师维修。',
    color: '#e68b35'
  },
  {
    value: 5,
    label: '待消毒',
    subLabel: '设备待消毒中。',
    color: '#e68b35'
  },
  {
    value: 6,
    label: '已归还',
    subLabel: '设备已归还。',
    color: '#67c23a'
  },
  {
    value: 7,
    label: '维修结束',
    subLabel: '设备维修已结束,可以继续使用。',
    color: '#5fb0ed'
  },
  {
    value: 8,
    label: '已消毒',
    subLabel: '设备维修已消毒,可以归还该设备。',
    color: '#5fb0ed'
  }
]

export default options

// 格式化状态获取状态配置项
export function formatStatusData(value) {
  const result = options.filter(item => {
    return item.value == value
  })
  if (result.length) return result[0]
  return {
    value: 0,
    label: '',
    color: '#5fb0ed'
  }
}

// 格式化状态获取状态名
export function formatStatusLabel(value) {
  const result = options.filter(item => {
    return item.value == value
  })
  if (result.length) return result[0].label
  return ''
}

// 格式化状态获取详细状态名
export function formatStatusSubLabel(value) {
  const result = options.filter(item => {
    return item.value == value
  })
  if (result.length) return result[0].subLabel
  return ''
}
