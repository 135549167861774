<!-- 
	使用方式请参考 @/views/shareAndDispatch/workManager/order/shareSetting/index.vue
	各项未设有间距，如需间距，请自行使用样式设置
 -->

<template>
  <div class="list-item" @click="click">
    <div class="row-1">
      <div class="title">{{ itemData[config.titleKey] }}</div>
      <div class="title-right">
        <slot name="titleRight"></slot>
      </div>
    </div>
    <div class="row-2">
      <div class="img-wrap">
        <img class="img" :src="image" alt=""/>
      </div>
      <div class="right-list">
        <p class="row-item" v-for="(item, index) in config.layout" :key="index">
          <span class="label">{{ item.label }}</span>
          <span class="value">{{ $isValue(itemData[item.key]) ? itemData[item.key] : '-' }}</span>
        </p>
      </div>
    </div>

    <slot name="custom-row"></slot>

    <div class="row-3" v-if="this.$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'listItem2',
  components: {},
  props: {
    itemData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    config: {
      type: Object,
      default: () => {
        return {
          titleKey: '',
          imgKey: '',
          layout: []
        }
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    image() {
      if (this.$valueIsExist(this.itemData, this.config.imgKey) && this.itemData[this.config.imgKey] != '') {
        let photo = this.itemData[this.config.imgKey]
        return this.$replacePhoto(`${window.ipConfig.VUE_APP_BASEURL}/api/image/get?id=${this.$getPhotosId(photo)}`)
      }
      return this.$replacePhoto('')
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    click() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';

.list-item {
  padding: 10px 12px;
  background: linear-gradient(180deg, #d1deff 0%, #ffffff 100%);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .row-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 24px;
    font-weight: 700;

    .title {
      font-size: 16px;
      color: #333333;
      width: calc(100% - 80px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .status-wrap {
      font-size: 14px;
    }
  }

  .row-2 {
    display: flex;
    align-items: flex-start;
    margin-top: 8px;

    .img-wrap {
      //width: 88px;
      //height: 88px;
      width: 60px;
      height: 60px;
      border-radius: 4px;
      overflow: hidden;

      img {
        //min-width: 60px;
        //min-height: 60px;
        //max-width: 88px;
        //max-height: 88px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .right-list {
      margin-left: 10px;
      width: calc(100% - 88px - 10px);
      line-height: 14px;

      .row-item {
        font-size: 12px;
        display: flex;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        .label {
          color: #999;
          width: 60px;
        }

        .value {
          width: calc(100% - 60px);
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .row-3 {
    margin-top: 8px;
  }
}
</style>
